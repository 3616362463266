import React from "react";
import dayjs from "dayjs";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  award: GatsbyTypes.ContentfulAward;
};

export const AwardHeader: React.FC<Props> = ({ award }) => {
  return (
    <>
      <style jsx>{`
        .head {
          align-items: center;
          display: flex;
          margin: 0;
        }
        .eventYear {
          background-color: ${colors.brand};
          border-radius: 4px;
          color: ${colors.white};
          font-size: 12px;
          font-weight: 700;
          line-height: 1;
          margin: 0 10px 0 0;
          padding: 6px 8px;
        }
        .company {
          font-size: 15px;
          line-height: 24px;
          font-weight: 500;
        }
        .title {
          line-height: 1.5;
          margin: 10px 0 0;
        }
        .dateContainer {
          display: flex;
          margin: 10px 0 0;
          letter-spacing: 0.06em;
        }
        .date {
          color: ${colors.lightText};
          font-size: 15px;
          line-height: 1;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 20px 20px 0;
          }
          .title {
            font-size: 20px;
          }
          .date {
            margin: 0;
          }
        }
        @media (${breakPoints.pc}) {
          .wrapper {
            padding: 30px 40px 0;
          }
          .title {
            font-size: 24px;
          }
          .date {
            margin: 0 10px 0 0;
          }
        }
      `}</style>
      <header className="wrapper">
        <div className="head">
          <span className="eventYear">{`${award.eventYear}年受賞企業`}</span>
          <span className="company">{award?.company?.name}</span>
        </div>
        <h1 className="title">{award.title}</h1>
        <div className="dateContainer">
          <div className="date">{dayjs(award.publishedAt).format("YYYY.MM.DD")}</div>
        </div>
      </header>
    </>
  );
};
