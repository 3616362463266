import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { renderHtmlAst } from "~/molecules/AwardBody";
import { ArticleCards } from "~/molecules/ArticleCards";
import { Layout } from "~/templates/Layout";
import { PATH } from "~/constant/path";
import { AwardHeader } from "~/molecules/AwardHeader";
import { ShareToSNS } from "~/atoms/ShareToSNS";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { siteWidth } from "~/utils/variables";
// @TODO: デザイン移行後に削除
import { RecommendedArticles } from "~/molecules/RecommendedArticles";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { ArticleSidebar } from "~/organisms/ArticleSidebar";

type Props = {
  pageContext: {
    contentfulId: string;
  };
  path: string;
  data: {
    site: GatsbyTypes.Site;
    award: GatsbyTypes.ContentfulAward;
    awardArticle: GatsbyTypes.ContentfulReport;
    awards: GatsbyTypes.ContentfulReportConnection;
    reports: GatsbyTypes.ContentfulReportConnection;
    cases: GatsbyTypes.ContentfulCaseConnection;
  };
};

const Award: React.FC<Props> = ({ data }) => {
  const { award, site } = data;
  const breadCrumbItems = [
    PATH.TOP,
    PATH.EVENTS_AWARD,
    {
      name: `${award.eventYear}年受賞企業`,
      to: `/awards/${award.eventYear}/`,
    },
    {
      name: award.title,
    },
  ];
  const pagePath = `${PATH.AWARD.to}/${award.contentful_id}`;
  const pageUrl = `${site?.siteMetadata?.siteUrl}${pagePath}`;

  const awards = data.awards.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/award/interview${edge.node.awardYear}/${edge.node.contentful_id}`,
    };
  });

  const cases = data.cases.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/cases/${edge.node.contentful_id}`,
    };
  });

  const reports = data.reports.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/${edge.node.eventCategory?.slug}/${(edge.node.eventCategory?.slug === "award" && `interview${edge.node.awardYear}/`) || ``
        }${edge.node.contentful_id}`,
    };
  });

  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.creamGray};
        }
        .container {
          display: flex;
          margin: 0 auto;
          max-width: ${siteWidth.default};
        }
        .article {
          background-color: ${colors.white};
          border-top: solid 5px ${colors.brand};
          margin: 0 30px;
        }
        .eyecatch {
          position: relative;
          margin: 30px auto 0;
          padding: 0 15px;
        }
        .awardFooter {
          border-top: 1px solid ${colors.gray};
          display: flex;
          padding: 0 40px;
        }
        .snsWrapper {
          padding: 20px 0;
        }
        @media (${breakPoints.sp}) {
          .container {
            flex-direction: column;
          }
          .article {
            margin: 20px 0 0 0;
            width: 100%;
          }
          .articleBody {
            padding: 30px 20px;
          }
          .awardFooter {
            padding: 0 20px;
          }
        }
        @media (${breakPoints.minPc}) {
          .container {
            padding: 30px 0 50px;
          }
          .article {
            border-radius: 5px;
            margin: 0 30px 0 0;
            width: calc(100% - 250px);
          }
          .articleBody {
            padding: 30px 40px;
          }
          .awardFooter {
            padding: 0 40px;
          }
        }
      `}</style>
      <Layout
        title={award.title}
        ogpImageUrl={award.eyecatch && `https:${award?.eyecatch?.fluid?.src}`}
        canonicalUrl={pageUrl}
        breadCrumbItems={breadCrumbItems}
        breadCrumbBgColor={colors.creamGray2}
      >
        <div className="wrapper">
          <div className="container">
            <article className="article">
              <AwardHeader award={award} />
              <div className="eyecatch">
                <Img fluid={award?.eyecatch?.fluid} />
              </div>
              <div className="articleBody">{renderHtmlAst(award.body?.childMarkdownRemark?.htmlAst)}</div>
              <div className="awardFooter">
                <div className="snsWrapper">
                  <ShareToSNS url={pagePath} title={award.title} />
                </div>
              </div>
            </article>
            <ArticleSidebar />
          </div>
        </div>
        <ArticleListSection title={`受賞企業インタビュー`}>
          <ArticleCards articles={awards} prefix="Awards" />
        </ArticleListSection>
        <ArticleListSection title="こちらもおすすめ">
          <RecommendedArticles articles={[...cases, ...reports]} />
        </ArticleListSection>
      </Layout>
    </>
  );
};

export default Award;

export const awardQuery = graphql`
  query Award($contentfulId: String) {
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    award: contentfulAward(contentful_id: { eq: $contentfulId }) {
      id
      contentful_id
      eventYear
      eyecatch {
        id
        contentful_id
        fluid(maxWidth: 800) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      title
      createdAt(fromNow: false)
      publishedAt(fromNow: false)
      company {
        name
        id
      }
      body {
        id
        childMarkdownRemark {
          htmlAst
        }
      }
    }
    awards: allContentfulReport(
      filter: { eventCategory: { slug: { eq: "award" } } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          companyName
          date
          awardYear
        }
      }
    }
    reports: allContentfulReport {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          companyName
          awardYear
          eventCategory {
            slug
          }
          date
        }
      }
    }
    cases: allContentfulCase {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          company {
            name
          }
          date
        }
      }
    }
  }
`;
