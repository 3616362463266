import React from "react";
import rehypeReact from "rehype-react";
import type { Node } from "unist";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import {
  Heading1,
  Heading2,
  Heading4 as Heading5,
  HyperLink,
  StyledUl,
  StyledOl,
  Blockquote,
  StyledImg,
  nl2br,
} from "./BaseBody";

export const Paragraph: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .paragraph {
          color: ${colors.defaultText};
          margin: 15px 0 0;
        }
        .paragraph:first-child {
          margin: 0;
        }
        .paragraph :global(strong) {
          font-weight: 700;
        }
        @media (${breakPoints.sp}) {
          .paragraph {
            font-size: 15px;
          }
        }
        @media (${breakPoints.pc}) {
          .paragraph {
            font-size: 16px;
          }
        }
      `}</style>
      <p className="paragraph">{node.children}</p>
    </>
  );
};

export const Heading3: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .heading3 {
          color: ${colors.defaultText};
          border-bottom: solid 1px ${colors.defaultText};
          margin: 30px 0 0 0;
        }
        .heading3:first-child {
          margin: 0;
        }
        @media (${breakPoints.sp}) {
          .heading3 {
            font-size: 18px;
          }
        }
        @media (${breakPoints.pc}) {
          .heading3 {
            font-size: 21px;
          }
        }
      `}</style>
      <h3 className="heading3">{node.children}</h3>
    </>
  );
};

export const Heading4: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .heading4 {
          color: ${colors.defaultText};
          margin: 30px 0 0 0;
          font-weight: 500;
        }
        .heading4:first-child {
          margin: 0;
        }
        @media (${breakPoints.sp}) {
          .heading4 {
            font-size: 16px;
          }
        }
        @media (${breakPoints.pc}) {
          .heading4 {
            font-size: 18px;
          }
        }
      `}</style>
      <h4 className="heading4">{node.children}</h4>
    </>
  );
};

export const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    h6: Heading5,
    p: Paragraph,
    a: HyperLink,
    ul: StyledUl,
    ol: StyledOl,
    blockquote: Blockquote,
    img: StyledImg,
  },
}).Compiler;

export const renderHtmlAst = (htmlAst) => renderAst(nl2br(htmlAst));
